.centerHead {
    text-align: center;
}

.vll {
    border-left: 2px solid black;
    height: 450px;
  }

.vlr {
    border-right: 2px solid black;
    height: 450px;
  }

.centerText {
  text-align: center;
}

.left {
  text-align: justify;
}

.isClosed, .isUnpaid {
  text-align: center;
  color: red;
}

.isOpen, .isPaid {
  text-align: center;
  color: green;
}

.raiseDisputeBtn, .downloadBtn {
  margin-left: 10px;
}

.raiseDisputeBtn {
  background-color: red;
  border-color: red;
}

.raiseDisputeBtn:hover {
  margin-left: 10px;
  background-color: crimson;
  border-color: crimson;
}

.legend {
  margin-top: 50px;
  font-size: 12px;
  color: darkgrey;
}